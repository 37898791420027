<template>
  <div class="flex flex-col flex-1 md:border-l md:pl-5 md:ml-5">
    <div class="flex flex-wrap items-baseline">
      <h4 class="text-sm font-bold mr-2">Risk rating</h4>
      <!-- last updated - this can be commented out -->
      <p v-if="lastUpdated" class="text-xs mr-2 text-gray-500">Last updated <span class="">{{ lastUpdated }}</span></p>
      <!-- /last updated -->
    </div>
    <!-- risk rating -->
    <div class="md:mt-auto flex flex-wrap md:flex-nowrap justify-between items-center">
      <div class="mt-4 mr-4 flex-1">
        <dashboard-risk-badge :badgeStyle="'large'" :risk="risk" />
      </div>
      <p class="mt-4 text-xs">
        Driven by the current risk index value the space's risk rating will be either <strong>Caution</strong>, <strong>Prepared</strong>, or <strong>Pending</strong>. <a target="_blank" class="text-pink-600 underline hover:no-underline" href="https://help.particleone.com/building-risk-rating/">Learn more</a>
      </p>
    </div>
  </div>
</template>

<script>
import { DashboardRisk } from '@/models/dashboard';
import DashboardRiskBadge from './DashboardRiskBadge.vue';

export default {
  name: 'DashboardSpaceSummaryCard',
  components: {
    DashboardRiskBadge
  },
  props: {
    risk: {
      type: DashboardRisk
    },
    lastUpdated: {
      type: String
    }
  }
};
</script>
